import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Typography,
} from "@mui/material";
import HeaderMenuRenderIcon from "./HeaderMenuRenderIcon";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
//TODO: implement navLink
const MenuList = styled(List)(({ theme }) => ({
  width: "375px",
}));

const ActiveLink = styled(Link)(({ theme }) => ({
  color: "grey",
  background: "#e3e5ea",
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
}));

const InactiveLink = styled(Link)(({ theme }) => ({
  color: "black",
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
}));

const IconStyle = styled(IconButton)(({ theme }) => ({
  marginRight: "8px",
}));

const HeaderMainMenuItems = ({
  openDrawer: drawerState,
  error,
  loading: isLoading,
  onSelectMenuItem,
}) => {
  const { t } = useTranslation("common");
  const location = useLocation();
  const navigate = useNavigate();
  const menuItems = useSelector((state) => state.auth.menuItems);
  const [expandedAccordion, setExpandedAccordion] = useState("");

  const handleAccordionChange = (accordionId) => (event, newExpandedAccordion) => {
    setExpandedAccordion(newExpandedAccordion ? accordionId : false);
  };

  const getActiveAccordion = () => {
    menuItems?.AccessRights?.forEach((menu) => {
      menu.AccessRights.forEach((submenu) => {
        if (submenu.path === location.pathname && !expandedAccordion) {
          setExpandedAccordion(submenu.parent);
        }
      });
    });
  };

  const handleDrawerToggle = (path) => {
    navigate(path);
    onSelectMenuItem(!drawerState);
  };

  useEffect(() => {
    getActiveAccordion();
    // eslint-disable-next-line 
  }, [menuItems, location.pathname]);

  return (
    <MenuList>
      {!isLoading && menuItems?.AccessRights?.length === 0 && !error && (
        <Typography variant="body1">{t("errors.noContent")}</Typography>
      )}
      {!isLoading && error && !menuItems && (
        <Typography variant="body1">{t(`${error}`)}</Typography>
      )}
      {isLoading && <Typography variant="body1">{t("loading")}</Typography>}
      {menuItems?.AccessRights?.map((menu) =>
        menu.AccessRights.length > 0 ? (
          <Accordion
            id={menu.id}
            key={menu.id}
            expanded={expandedAccordion === menu.moduleName}
            onChange={handleAccordionChange(menu.moduleName)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <ListItemIcon>{HeaderMenuRenderIcon(menu.icon)}</ListItemIcon>
              <Typography variant="body1">
                {menu.type === "mainLink" &&
                  t(`menu.menuItems.${menu.moduleName}.title`)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
            {menu.AccessRights.map(
              (submenu) =>
                submenu.parentId && (
                  <React.Fragment key={submenu.id}>
                    {location.pathname === submenu.path ? (
                      <ActiveLink
                        to={submenu.path}
                        onClick={() => handleDrawerToggle(submenu.path)}
                      >
                        <IconStyle>{HeaderMenuRenderIcon(submenu.icon)}</IconStyle>
                        <Typography variant="body2">
                          {submenu.type === "subLink" &&
                            t(
                              `menu.menuItems.${menu.moduleName}.subItems.${submenu.moduleName}`
                            )}
                        </Typography>
                      </ActiveLink>
                    ) : (
                      <InactiveLink
                        to={submenu.path}
                        onClick={() => handleDrawerToggle(submenu.path)}
                      >
                        <IconStyle>{HeaderMenuRenderIcon(submenu.icon)}</IconStyle>
                        <Typography variant="body2">
                          {submenu.type === "subLink" &&
                            t(
                              `menu.menuItems.${menu.moduleName}.subItems.${submenu.moduleName}`
                            )}
                        </Typography>
                      </InactiveLink>
                    )}
                  </React.Fragment>
                )
            )}
            </AccordionDetails>
          </Accordion>
        ) : (
          !menu.parentId &&
          menu.type === "mainLink" && (
            <ListItem
              button
              key={menu.id}
              onClick={() => handleDrawerToggle(menu.path)}
              component={location.pathname === menu.path ? ActiveLink : null}
            >
              <ListItemIcon>{HeaderMenuRenderIcon(menu.icon)}</ListItemIcon>
              <ListItemText
                primary={t(`menu.menuItems.${menu.moduleName}.title`)}
              />
            </ListItem>
          )
        )
      )}
    </MenuList>
  );
};
export default HeaderMainMenuItems;

import React, { Fragment, useEffect, useState } from "react";
import useInput from "../../hooks/use-input";
import useHttp from "../../hooks/use-http";
import { useSelector } from "react-redux";
import {
  Stack,
  Fab,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
  InputAdornment,
  Typography,
  FilledInput,
  FormHelperText,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Close } from "@mui/icons-material";

const ModelForm = ({
  model,
  translationModel,
  action,
  selectedCashDrawerId,
  cashDrawerLastOperation,
  cashRegisterMovementType,
  onCreate,
  onUpdate,
  onCancel,
}) => {
  const { t } = useTranslation("common");
  const token = useSelector((state) => state.auth.token);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [formError, setFormError] = useState(false);
  let types = ["1", "2", "3", "4", "5", "6", "7"];
  if (cashRegisterMovementType === "transaction") {
    types = ["3", "4", "5", "6", "7"];
  }
  let loadForm = true;

  const findTranslation = (key, index) => {
    if (i18next.exists(`common:cashOperations.form.movementTypes.${key}`)) {
      return (
        <MenuItem key={index} value={key}>
          {t([`cashOperations.form.movementTypes.${key}`])}
        </MenuItem>
      );
    }
  };

  const {
    enteredValue: type,
    setEnteredValue: setType,
    valueIsValid: typeIsValid,
    hasError: typeHasError,
    valueChangeHandler: typeChangeHandler,
    inputBlurHandler: typeBlurHandler,
    reset: resetType,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    enteredValue: amount,
    setEnteredValue: setAmount,
    valueIsValid: amountIsValid,
    hasError: amountHasError,
    valueChangeHandler: amountChangeHandler,
    inputBlurHandler: amountBlurHandler,
    reset: resetAmount,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    enteredValue: observations,
    setEnteredValue: setObservations,
    valueChangeHandler: observationsChangeHandler,
    inputBlurHandler: observationsBlurHandler,
    reset: resetObservations,
  } = useInput();

  const {
    enteredValue: cashDrawer,
    setEnteredValue: setCashDrawer,
    hasError: cashDrawerHasError,
    inputBlurHandler: cashDrawerBlurHandler,
    reset: resetCashDrawer,
  } = useInput();

  const cashDrawerChangeHandler = (event) => {
    setCashDrawer(event.target.value);
    setFormError(false);
    if (!cashDrawerLastOperation) {
      getCashDrawerLastOperation(event.target.value);
    }
  };

  const {
    data: cashDrawers,
    isLoading: cashDrawersAreLoading,
    sendRequest: fetchCashDrawers,
  } = useHttp();

  const getCashDrawers = () => {
    fetchCashDrawers({
      url: process.env.REACT_APP_API_SERVER + "/cash_drawers",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    data: cashDrawerGetLastOperation,
    isLoading: cashDrawerGetLastOperationAreLoading,
    sendRequest: fetchCashDrawerGetLastOperation,
  } = useHttp();

  const getCashDrawerLastOperation = (cashDrawerId) => {
    fetchCashDrawerGetLastOperation({
      url: `${process.env.REACT_APP_API_SERVER}/cash_operations/last_operation/${cashDrawerId}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    enteredValue: captured,
    setEnteredValue: setCaptured,
    reset: resetCaptured,
  } = useInput();

  let formIsValid = false;

  if ((selectedCashDrawerId || cashDrawer) && type !== "1" && type !== "2") {
    formIsValid = true;
  }
  if (!selectedCashDrawerId && (type === "1" || type === "2")) {
    if (typeIsValid && amountIsValid && cashDrawer) {
      formIsValid = true;
    }
  }

  const formSubmitHandler = async (event) => {
    event.preventDefault();

    if (action === "add") {
      const modelData = {
        casOpeType: type,
        casOpeAmount: amount ? amount : null,
        casOpeObservations: observations,
        casOpeDraID: cashDrawer,
      };
      onCreate(modelData);
    }

    if (action === "edit") {
      const modelData = {
        id: model.CashOperation.id,
        casOpeType: type,
        casOpeAmount: amount ? amount : null,
        casOpeObservations: observations,
        casOpeDraID: cashDrawer,
      };
      onUpdate(modelData);
    }
    resetType();
    resetAmount();
    resetObservations();
    resetCashDrawer();
    resetCaptured();
  };

  const cancelHandler = () => {
    onCancel();
  };

  const closeDialogHandler = () => {
    setDialogOpen(false);
  };

  const validateCashOperation = (movementType) => {
    if (
      cashDrawerGetLastOperation &&
      !cashDrawerGetLastOperationAreLoading &&
      cashDrawerGetLastOperation.CashOperation?.casOpeType === movementType
    ) {
      setDialogOpen(true);
      setFormError(true);
    }
  };

  const getDialogTitle = () => {
    if (cashRegisterMovementType === "1" || type === "1") {
      return t(translationModel + ".confirmations.cashDrawerAlreadyOpen.title");
    } else if (cashRegisterMovementType === "2" || type === "2") {
      return t(
        translationModel + ".confirmations.cashDrawerAlreadyClosed.title"
      );
    }
  };

  const getDialogDescription = () => {
    if (cashRegisterMovementType === "1" || type === "1") {
      return t(
        translationModel + ".confirmations.cashDrawerAlreadyOpen.description"
      );
    } else if (cashRegisterMovementType === "2" || type === "2") {
      return t(
        translationModel + ".confirmations.cashDrawerAlreadyClosed.description"
      );
    }
  };

  useEffect(() => {
    if (loadForm) {
      getCashDrawers();
      cashRegisterMovementType && validateCashOperation(cashRegisterMovementType);
      !cashRegisterMovementType && cashDrawerGetLastOperation && type && validateCashOperation(type)

      if (model && action) {
        setType(
          model.CashOperation.casOpeType ? model.CashOperation.casOpeType : ""
        );
        setAmount(
          model.CashOperation.casOpeAmount
            ? model.CashOperation.casOpeAmount
            : ""
        );
        setObservations(
          model.CashOperation.casOpeObservations
            ? model.CashOperation.casOpeObservations
            : ""
        );
        setCashDrawer(
          model.CashOperation.casOpeDraID ? model.CashOperation.casOpeDraID : ""
        );
        setCaptured(
          model.CashOperation.executive
            ? model.CashOperation.executive?.exeInitials
            : ""
        );
      } else if (
        cashDrawerGetLastOperation &&
        (cashRegisterMovementType === "1" || cashRegisterMovementType === "2")
      ) {
        setType(cashRegisterMovementType);
      }

      //TODO: Assign current user id
      if (action === "add") {
        selectedCashDrawerId && setCashDrawer(selectedCashDrawerId);

        if (
          cashDrawerLastOperation &&
          cashDrawerLastOperation.CashOperation &&
          cashRegisterMovementType !== "transaction" &&
          cashDrawerLastOperation.CashOperation.casOpeType === "2"
        ) {
          setType(1);
        }

        if (
          cashDrawerLastOperation &&
          cashDrawerLastOperation.CashOperation &&
          cashRegisterMovementType !== "transaction" &&
          cashDrawerLastOperation.CashOperation.casOpeType === "1"
        ) {
          setType(2);
        }

        if (
          cashDrawerLastOperation &&
          cashDrawerLastOperation.CashOperation &&
          cashRegisterMovementType !== "transaction" &&
          cashDrawerLastOperation.CashOperation.casOpeType !== "1" &&
          cashDrawerLastOperation.CashOperation.casOpeType !== "2"
        ) {
          setType(2);
        }

        if (cashDrawerLastOperation && !cashDrawerLastOperation.CashOperation) {
          setType(1);
        }
      }
    }
    // eslint-disable-next-line
    loadForm = false;
  }, [loadForm, model, cashDrawerGetLastOperation]);

  return (
    <Fragment>
      <Dialog
        open={dialogOpen}
        onClose={closeDialogHandler}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{getDialogTitle()}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {getDialogDescription()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialogHandler} autoFocus>
            {t(translationModel + ".confirmations.buttons.accept")}
          </Button>
        </DialogActions>
      </Dialog>
      <Stack
        className={`${styles["form-stack"]}`}
        spacing={1}
        justifyContent="center"
      >
        <Typography variant="h5">
          <Fab
            className={`${styles["action-buttons"]} ${styles["back-button"]}`}
            size="small"
            aria-label="back"
            onClick={() => cancelHandler()}
          >
            <Close fontSize="small" />
          </Fab>
        </Typography>
        {cashRegisterMovementType !== "1" &&
          cashRegisterMovementType !== "2" &&
          (!cashDrawerLastOperation ||
            cashRegisterMovementType === "transaction") && (
            <FormControl error={typeHasError ? true : false}>
              <InputLabel id="title_label">
                {t(translationModel + ".form.movementType")}*
              </InputLabel>
              <Select
                id="type"
                labelId="type_label"
                value={type}
                label={t(translationModel + ".form.movementType")}
                onChange={typeChangeHandler}
                onBlur={typeBlurHandler}
                disabled={action === "view" || action === "edit"}
              >
                {types.map((type, index) => findTranslation(type, index))}
              </Select>
              {typeHasError && (
                <FormHelperText id="name-helper-text">
                  {t("errors.fieldError")}
                </FormHelperText>
              )}
            </FormControl>
          )}
        {!selectedCashDrawerId && !cashDrawersAreLoading && (
          <FormControl error={cashDrawerHasError ? true : false}>
            <InputLabel id="cashDrawer_label">
              {t(translationModel + ".form.cashDrawer")}*
            </InputLabel>
            <Select
              id="cashDrawer"
              labelId="cashDrawer_label"
              value={cashDrawer}
              label={t(translationModel + ".form.cashDrawer")}
              onChange={cashDrawerChangeHandler}
              onBlur={cashDrawerBlurHandler}
              disabled={action === "view" || action === "edit"}
            >
              {cashDrawers &&
                cashDrawers.CashDrawers.map((cashDrawer) => (
                  <MenuItem key={cashDrawer.id} value={cashDrawer.id}>
                    {cashDrawer.casDraName}
                  </MenuItem>
                ))}
            </Select>
            {cashDrawerHasError && (
              <FormHelperText id="cashDrawer-helper-text">
                {t("errors.fieldError")}
              </FormHelperText>
            )}
          </FormControl>
        )}
        <FormControl
          error={
            amountHasError && type && type !== 1 && type !== 2 ? true : false
          }
          variant="outlined"
        >
          <InputLabel htmlFor="amount">
            {t(translationModel + ".form.amount")}
            {type && type !== 1 && type !== 2 && "*"}
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="amount"
            onChange={amountChangeHandler}
            onBlur={amountBlurHandler}
            value={amount}
            endAdornment={
              amount !== "" &&
              action !== "view" &&
              type &&
              type !== 1 &&
              type !== 2 && (
                <InputAdornment position="start">
                  <Clear onClick={resetAmount} />
                </InputAdornment>
              )
            }
            type="number"
            label={t(translationModel + ".form.amount")}
            disabled={action === "view" || action === "edit"}
          />
          {amountHasError && (
            <FormHelperText id="amount-helper-text">
              {t("errors.fieldError")}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="observations">
            {t(translationModel + ".form.observations")}
          </InputLabel>
          <FilledInput
            multiline
            maxRows={4}
            sx={{ background: "white" }}
            id="observations"
            onChange={observationsChangeHandler}
            onBlur={observationsBlurHandler}
            value={observations}
            endAdornment={
              observations !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetObservations} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.observations")}
            disabled={action === "view"}
          />
        </FormControl>
        {!selectedCashDrawerId && cashDrawersAreLoading && (
          <CircularProgress color="inherit" />
        )}
        {action === "view" && (
          <FormControl variant="outlined">
            <InputLabel htmlFor="captured">
              {t(translationModel + ".form.captured")}
            </InputLabel>
            <FilledInput
              sx={{ background: "white" }}
              id="captured"
              value={captured}
              type="text"
              label={t(translationModel + ".form.captured")}
              disabled
            />
          </FormControl>
        )}
        {action !== "view" && (
          <Button
            id="submit_form"
            variant="contained"
            onClick={formSubmitHandler}
            disabled={!formIsValid || formError}
          >
            {action === "add" &&
              t("buttons.add", { model: t(translationModel + ".model") })}
            {action !== "add" && t("buttons.save")}
          </Button>
        )}
        <Button
          id="cancel_form"
          variant="contained"
          color={action === "view" ? "inherit" : "warning"}
          onClick={cancelHandler}
        >
          {action === "view" && t("buttons.back")}
          {action !== "view" && t("buttons.cancel")}
        </Button>
      </Stack>
    </Fragment>
  );
};

export default ModelForm;

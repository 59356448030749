import React, { useState, useEffect, Fragment } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { uiActions } from "../../store/ui-slice";
import { authActions } from "../../store/auth-slice";
import { useTheme } from "@mui/material/styles";
import useHttp from "../../hooks/use-http";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Paper,
  Typography,
  Backdrop,
  CircularProgress,
  Alert,
} from "@mui/material";
import AddModelButton from "../UI/AddModelButton";
import styles from "./styles.module.css";
import ModelGrid from "./ModelGrid";
import ModelForm from "./ModelForm";
import POS from "../POS/POS";

const Sales = ({ token, pendingCharges, onSetPageTitle }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const actionParam = query.get("action");
  const [createSuccess, setCreateSuccess] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [action, setAction] = useState("");
  const pageSize = parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE, 10);
  const modelName = "sales";
  const translationModel = "sales";
  const saleId = useSelector((state) => state.ui.saleId);
  const defaultWarehouse = useSelector((state) => state.auth.defaultWarehouse);
  const { clientId } = useParams();
  let refreshGrid = true;
  const isReport = () => {
    return location.pathname.includes("/reports") ? true : false;
  };

  const {
    isLoading: modelIsLoading,
    error: fetchModelError,
    data: model,
    setData: setModel,
    sendRequest: sendModelGetRequest,
  } = useHttp();

  const {
    isLoading: modelRowsAreLoading,
    error: fetchModelRowsError,
    data: modelRows,
    sendRequest: sendModelRowsGetRequest,
  } = useHttp();

  const {
    isLoading: createIsLoading,
    error: fetchCreateError,
    data: createData,
    setData: setCreateData,
    sendRequest: sendCreateRequest,
  } = useHttp();

  const {
    isLoading: updateIsLoading,
    error: fetchUpdateError,
    sendRequest: sendUpdateRequest,
  } = useHttp();

  const {
    isLoading: deleteIsLoading,
    error: fetchDeleteError,
    sendRequest: sendDeleteRequest,
  } = useHttp();

  const getModelRowsHandler = async (page, size, searchText) => {
    const reqPage = page > 0 ? page : 1;
    const reqSize = size > 0 ? size : pageSize;
    const baseUrl = `${process.env.REACT_APP_API_SERVER}/${modelName}`;
    let url = clientId
      ? `${baseUrl}/client/${clientId}?page=${reqPage}&pageSize=${reqSize}&search=${
          searchText ?? ""
        }`
      : `${baseUrl}?page=${reqPage}&pageSize=${reqSize}&search=${
          searchText ?? ""
        }`;
    if (pendingCharges) {
      url += `&chargePending=${pendingCharges}`;
    }
    await sendModelRowsGetRequest({
      url,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const createdHandler = async () => {
    const modelData = {
      salExecutiveID: 1,
      salClientID: clientId ? clientId : null,
      salCaptured: 1,
      salWarOutput: defaultWarehouse,
      salTotalTaxes: 0,
      salTotalItems: 0,
      salCreationDate: new Date(),
      salDate: new Date(),
    };

    await sendCreateRequest({
      url: `${process.env.REACT_APP_API_SERVER}/${modelName}/create`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
      body: modelData,
    });

    if (!fetchCreateError) {
      setCreateSuccess(true);
      setTimeout(() => {
        setCreateSuccess(false);
      }, process.env.REACT_APP_ALERTS_TIMEOUT);
      setModel("");
      clientId ? navigate(`/sales/client/${clientId}`) : navigate("/sales");
    }
  };

  const updateHandler = async (modelData) => {
    await sendUpdateRequest({
      url: `${process.env.REACT_APP_API_SERVER}/${modelName}/`,
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
      body: modelData,
    });

    if (!fetchUpdateError) {
      setUpdateSuccess(true);
      setTimeout(() => {
        setUpdateSuccess(false);
      }, process.env.REACT_APP_ALERTS_TIMEOUT);
    }
  };

  const deleteHandler = async (modelId) => {
    await sendDeleteRequest({
      url: `${process.env.REACT_APP_API_SERVER}/${modelName}/`,
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
      body: { id: modelId },
    });

    if (!fetchDeleteError) {
      getModelRowsHandler(0);
      setDeleteSuccess(true);
      setTimeout(() => {
        setDeleteSuccess(false);
      }, process.env.REACT_APP_ALERTS_TIMEOUT);
    }
  };

  const searchHandler = (searchText) => {
    const search = searchText ? searchText : "";
    getModelRowsHandler(1, pageSize, search);
  };

  const viewHandler = (modelId) => {
    setAction("view");
    dispatch(uiActions.setSaleId(modelId));
  };

  const editHandler = (modelId) => {
    setAction("edit");
    dispatch(uiActions.setSaleId(modelId));
  };

  const cancelHandler = () => {
    dispatch(uiActions.setSaleId(null));
    setModel("");
    setAction("");
    setCreateData(null);
    getModelRowsHandler(0);
  };

  const setPageTitleHandler = () => {
    onSetPageTitle(
      pendingCharges
        ? t(translationModel + ".title.salesChargePending")
        : t(translationModel + ".title.sales")
    );
  };

  const pageChangeHandler = (page, size, search) => {
    getModelRowsHandler(page + 1, size, search);
  };

  const pageSizeChangeHandler = (size, search) => {
    getModelRowsHandler(1, size, search);
  };

  useEffect(() => {
    setPageTitleHandler();
    dispatch(authActions.getDefaultWarehouse());
    if (createData) {
      editHandler(createData.sale.id);
    }
    if (refreshGrid === true) {
      actionParam === "view" && id && viewHandler(id);
      actionParam === "edit" && id && editHandler(id);

      getModelRowsHandler(0);
    }
    // eslint-disable-next-line
    refreshGrid = false;
  }, [
    sendModelGetRequest,
    sendModelRowsGetRequest,
    sendDeleteRequest,
    refreshGrid,
    createData,
    pendingCharges,
  ]);

  return (
    <Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={
          modelIsLoading ||
          modelRowsAreLoading ||
          deleteIsLoading ||
          updateIsLoading ||
          createIsLoading
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {(!action || action === "add") && (
        <Typography id={modelName + "_title"} mt="100px" pb="0px" variant="h4">
          {pendingCharges
            ? t(translationModel + ".title.salesChargePending")
            : t(translationModel + ".title.sales")}{" "}
          {action && "- " + t("actions." + action)}
          {!action && !pendingCharges && (
            <AddModelButton
              modelName={modelName}
              translationModel={translationModel}
              onAdd={createdHandler}
            />
          )}
        </Typography>
      )}
      {(fetchModelError ||
        fetchModelRowsError ||
        fetchCreateError ||
        fetchUpdateError ||
        fetchDeleteError) && (
        <Alert severity="error">{t("errors.defaultError")}</Alert>
      )}
      {!fetchCreateError && createSuccess && (
        <Alert id="alert_create_success" severity="success">
          {t("confirmations.createSuccess", {
            model: t(translationModel + ".model"),
          })}
        </Alert>
      )}
      {!fetchUpdateError && updateSuccess && (
        <Alert id="alert_update_success" severity="success">
          {t("confirmations.updateSuccess", {
            model: t(translationModel + ".model"),
          })}
        </Alert>
      )}
      {!fetchDeleteError && deleteSuccess && (
        <Alert id="alert_delete_success" severity="success">
          {t("confirmations.deleteSuccess", {
            model: t(translationModel + ".model"),
          })}
        </Alert>
      )}
      {(!modelRowsAreLoading || deleteIsLoading) && modelRows && !action && (
        <Paper className={styles.datagrid}>
          <ModelGrid
            clientId={clientId}
            isLoading={modelRowsAreLoading}
            isReport={isReport()}
            modelName={modelName}
            modelRows={modelRows}
            smallScreen={smallScreen}
            translationModel={translationModel}
            onDeleteModel={deleteHandler}
            onEditModel={editHandler}
            onRefreshGrid={getModelRowsHandler}
            onPageChange={pageChangeHandler}
            onPageSizeChange={pageSizeChangeHandler}
            onSearch={searchHandler}
            onViewModel={viewHandler}
          />
        </Paper>
      )}
      {action && action === "add" && (
        <ModelForm
          action={action}
          model={model}
          translationModel={translationModel}
          onCancel={cancelHandler}
          onCreate={createdHandler}
          onUpdate={updateHandler}
        />
      )}
      {saleId && action && (action === "edit" || action === "view") && (
        <POS token={token} action={action} onCancel={cancelHandler} />
      )}
    </Fragment>
  );
};
export default Sales;
